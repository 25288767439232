import React, { FC, useEffect, useState } from 'react';
import { PrimaryButton, Seo } from 'components';
import Header from 'components/Header';
import Text from 'components/Text';
import { useRouter } from 'apis/history';
import styled from 'styled-components';
import { usePageView, useQuizData } from 'utils/hooks';
import { tablet, useQuery } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import Mark from 'assets/icons/red-mark.svg';
import Arrow from 'assets/icons/quiz/personal-summary-arrow.svg';
import quizSvgs from 'utils/constants/quizSvgs';
import ResultsProgress from 'components/ResultsProgress';
import { calculateBMI } from 'utils/units';

const ResultsSummary: FC = () => {
  const { goToResultsFatBurning } = useRouter();
  const data = useQuizData('resultsSummary');
  const [animate, setAnimate] = useState(false);
  const [leftPosition, setLeftPosition] = useState('0%');
  const { isMobile, isTablet } = useQuery();
  const quizAnswers = useSelector(
    (state: AppState) => state.user?.quiz_answers,
  );

  const age = Number(quizAnswers?.age?.year) + 9;

  useEffect(() => {
    setAnimate(true);
  }, []);

  const bmi = calculateBMI({
    height: quizAnswers?.height?.cm,
    weight: quizAnswers?.current_weight?.kg,
  });

  const renderSvgImage = (svgKey: string) => {
    const Svg = quizSvgs[svgKey];
    return <Svg />;
  };

  const resolveBoxInfo = () => {
    switch (true) {
      case bmi < 18.5:
        return {
          border: '#FDF8E2',
          bg: '#FDF8E2',
          leftPosition: `4%`,
          img: 'blueMark',
          type: 'Your weight category: <span>Underweight</span>',
          typeColor: '#6DCAED',
        };

      case bmi >= 18.5 && bmi < 25:
        return {
          border: '#E1FCDA',
          bg: '#E1FCDA',
          leftPosition: `30%`,
          img: 'greenCheck',
          type: 'Your weight category: <span>Healthy</span>',
          typeColor: '#52AA48',
        };

      case bmi >= 25 && bmi < 30:
        return {
          border: '#FDF8E2',
          bg: '#FDF8E2',
          leftPosition: isMobile ? `55%` : `57%`,
          img: 'yellowMark',
          type: 'Your weight category: <span>Overweight</span>',
          typeColor: '#ED9E53',
        };

      case bmi >= 30:
        return {
          border: '#FDE5E2',
          bg: '#FDE5E2',
          leftPosition: isMobile ? `78%` : `82%`,
          img: 'redMark',
          type: 'Your weight category: <span>Obese</span>',
          typeColor: '#DC4B4B',
        };
    }
  };
  const [renderCount, setRenderCount] = useState(0);

  useEffect(() => {
    setRenderCount(prev => prev + 1);
    const resolvedInfo = resolveBoxInfo();
    if (resolvedInfo) {
      setAnimate(false);
      setTimeout(() => {
        setLeftPosition(resolvedInfo.leftPosition);
        setAnimate(true);
      }, 10);
    }
  }, []);

  return (
    <>
      <Seo />
      <Outter>
        <Header sticky={false} />
        <ResultsProgress step={1} />
      </Outter>

      <Container>
        <Title>{data?.label}</Title>
        <Subtitle dangerouslySetInnerHTML={{ __html: data?.subtitle }} />
        <Content>
          <TopTitleContainer>
            <SectionTitle>{data?.topTitle}</SectionTitle>
          </TopTitleContainer>
          <ChartContainer>
            <BubbleContainer
              animate={animate}
              leftPosition={leftPosition}
              key={renderCount}
            >
              <YouText bgColor={resolveBoxInfo()?.typeColor as string}>
                You - {bmi}
                <Arrow />
              </YouText>
              <Bubble bgColor={resolveBoxInfo()?.typeColor as string} />
            </BubbleContainer>

            <Line />
            <Levels>
              {data?.levelTexts.map((item, index) => (
                <LevelText key={index}>{item}</LevelText>
              ))}
            </Levels>
          </ChartContainer>

          <BoxInfo
            bgColor={resolveBoxInfo()?.bg as string}
            border={resolveBoxInfo()?.border as string}
          >
            {renderSvgImage(resolveBoxInfo()?.img as string)}
            <BoxInner
              dangerouslySetInnerHTML={{
                __html: resolveBoxInfo()?.type,
              }}
            />
          </BoxInfo>
        </Content>
        <Content>
          <TopTitleContainer>
            <SectionTitle>{data?.metaboclicTitle}</SectionTitle>
            <MetabolicAge>{age} years</MetabolicAge>
          </TopTitleContainer>

          <BoxInfoBottom bgColor={resolveBoxInfo()?.typeColor as string}>
            <Mark />
            <BoxInner
              dangerouslySetInnerHTML={{ __html: data?.metabolicText }}
            />
          </BoxInfoBottom>
        </Content>
        <ButtonContainer>
          <ContinueButtonStyled onClick={goToResultsFatBurning}>
            {data?.buttonTitle}
          </ContinueButtonStyled>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default ResultsSummary;

const Outter = styled.section`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 3;
`;

const Container = styled.div`
  padding: 2rem 1rem 6rem;
  max-width: 30.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
    padding: 1.5rem 1rem 6rem;
  }
`;

const Title = styled.p`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 0.5rem;
  @media ${tablet} {
    font-size: 1.5rem;
    padding-bottom: 0.375rem;
  }
`;

const SectionTitle = styled.p`
  color: #000;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media ${tablet} {
    font-size: 1.125rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  max-width: 28.75rem;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin: 0 auto 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid #f5f5f5;
  background: #f9f9f9;
  position: relative;
`;

const TopTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ChartContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3.13rem;
  @media ${tablet} {
    margin-top: 2.62rem;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 0.52006rem;
  border-radius: 625rem;
  background: linear-gradient(
    90deg,
    #6dcaed 0%,
    #52aa48 32.99%,
    #f7d158 58.72%,
    #dc4b4b 100%
  );
`;

const Levels = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  margin: 0 1rem;
`;

const LevelText = styled.p`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const BubbleContainer = styled.div<{ animate: boolean; leftPosition: string }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.57rem;
  top: -40px;
  left: ${({ leftPosition }) => leftPosition}; /* Use the leftPosition prop */
  transition: left 1.5s ease-out; /* Smooth transition for 'left' property */
`;

const YouText = styled.div<{ bgColor: string }>`
  display: inline-flex;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.1875rem;
  background: ${({ bgColor }) => bgColor};
  color: #fff;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  position: relative;
  text-wrap: nowrap;
  svg {
    position: absolute;
    bottom: -6px;
    path {
      fill: ${({ bgColor }) => bgColor};
    }
  }
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const MetabolicAge = styled.p`
  color: #dc4b4b;
  text-align: right;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media ${tablet} {
    font-size: 1.125rem;
  }
`;

const Bubble = styled.div<{ bgColor: string }>`
  width: 1rem;
  height: 1rem;
  background: #fff;
  border: 4.878px solid ${({ bgColor }) => bgColor};
  border-radius: 3rem;
`;

const KmiText = styled(Text)`
  color: #777;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const BoxInfo = styled.div<{ bgColor: string; border: string }>`
  display: flex;
  padding: 0.75rem;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.5rem;
  width: 100%;
  background: ${({ bgColor }) => bgColor};
  border: 1px solid ${({ border }) => border};
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-wrap: nowrap;
  margin-top: 1.5rem;
  span {
    font-weight: 600;
    margin-left: 2px;
  }
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    border-top: 1px solid #f5f5f5;
    background: #fff;
  }
`;

const BoxInner = styled.section`
  display: flex;
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const Subtitle = styled.p`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-bottom: 1.5rem;
  span {
    font-weight: 600;
  }
`;

const BoxInfoBottom = styled(BoxInfo)`
  border: 1px solid #fde5e2;
  background: #fde5e2;
`;
